import { baseApi } from '../baseApi';
import { generateQueryString } from '../queryString';

import { PackagePurchaseRequest } from './PackagePurchaseRequest';
import { Transaction } from './Transaction';
import { TransactionCancelCardRequest } from './TransactionCancelCardRequest';
import { TransactionCardRequest } from './TransactionCardRequest';
import { TransactionChangeTenderRequest } from './TransactionChangeTenderRequest';
import { TransactionCheckFree } from './TransactionCheckFree';
import { TransactionClearTableRequest } from './TransactionClearTableRequest';
import { TransactionCompleteRequest } from './TransactionCompleteRequest';
import { TransactionConfirmEftFailureRequest } from './TransactionConfirmEftFailureRequest';
import { TransactionLinkCustomerLoyaltyRequest } from './TransactionLinkCustomerLoyaltyRequest';
import { TransactionLinkLoyaltyCodeRequest } from './TransactionLinkLoyaltyCodeRequest';
import { TransactionManualEftCheckRequest } from './TransactionManualEftCheckRequest';
import { TransactionNoSaleRequest } from './TransactionNoSaleRequest';
import { TransactionOfflineCardPaymentRequest } from './TransactionOfflineCardPaymentRequest';
import { TransactionOpenRequest } from './TransactionOpenRequest';
import { TransactionPrintRequest } from './TransactionPrintRequest';
import { TransactionRedeemBookingRequest } from './TransactionRedeemBookingRequest';
import { TransactionRefundRequest } from './TransactionRefundRequest';
import { TransactionSaveToStoredTable } from './TransactionSaveToStoredTable';
import { TransactionSearchRequest } from './TransactionSearchRequest';
import { TransactionSetTableRequest } from './TransactionSetTableRequest';
import { TransactionSplitMoveItemRequest } from './TransactionSplitMoveItemRequest';
import { TransactionSplitRequest } from './TransactionSplitRequest';
import { TransactionStoreRequest } from './TransactionStoreRequest';
import { TransactionTenderRequest } from './TransactionTenderRequest';
import { TransactionTenderResult } from './TransactionTenderResult';
import { TransactionVoidRequest } from './TransactionVoidRequest';
import { TransactionWasteResponse } from './TransactionWasteResponse';

const transactionSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Transactions'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTransactionForOperator: builder.query<Transaction[], number>({
        query: (operatorId: number) => ({
          url: `/Transactions/GetTransactionForOperator?operatorId=${operatorId}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'Transactions' as const,
                  id,
                })),
                { type: 'Transactions', id: 'LIST' },
              ]
            : [{ type: 'Transactions', id: 'LIST' }],
        keepUnusedDataFor: 0,
      }),
      getTransaction: builder.query<Transaction, number>({
        query: (transactionId: number) => ({
          url: `/Transactions?transactionId=${transactionId}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) => (result ? [{ type: 'Transactions', id: result.id }] : []),
        keepUnusedDataFor: 0,
      }),
      getStoredTransactions: builder.query<Transaction[], void>({
        query: () => ({
          url: `/Transactions/Stored`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'Transactions' as const,
                  id,
                })),
                { type: 'Transactions', id: 'LIST' },
              ]
            : [{ type: 'Transactions', id: 'LIST' }],
        keepUnusedDataFor: 0,
      }),
      checkTableFree: builder.query<TransactionCheckFree, number>({
        query: (tableId: number) => ({
          url: `/Transactions/CheckTableFree?tableId=${tableId}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        keepUnusedDataFor: 0,
      }),
      searchTransactions: builder.query<Transaction[], TransactionSearchRequest>({
        query: (tsr: TransactionSearchRequest) => ({
          url: `/Transactions/Search?operatorId=${tsr.operatorId}&searchText=${tsr.searchText}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'Transactions' as const,
                  id,
                })),
                { type: 'Transactions', id: 'LIST' },
              ]
            : [{ type: 'Transactions', id: 'LIST' }],
        keepUnusedDataFor: 0,
      }),
      createTransaction: builder.mutation<Transaction, number>({
        query: (operatorId: number) => ({
          url: `/Transactions?operatorId=${operatorId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      storeTransaction: builder.mutation<void, TransactionStoreRequest>({
        query: (tsr: TransactionStoreRequest) => ({
          url: `/Transactions?operatorId=${tsr.operatorId}&transactionId=${tsr.transactionId}&tableId=${tsr.tableId}&transactionName=${tsr.transactionName}&covers=${tsr.covers}`,
          method: 'PUT',
        }),
        invalidatesTags: ['Transactions'],
      }),
      saveToStoredTransaction: builder.mutation<void, TransactionSaveToStoredTable>({
        query: (tsr: TransactionSaveToStoredTable) => ({
          url: `/Transactions/SaveToStored?operatorId=${tsr.operatorId}&transactionId=${tsr.transactionId}&tableId=${tsr.tableId}`,
          method: 'PUT',
        }),
        invalidatesTags: ['Transactions'],
      }),
      openTransaction: builder.mutation<Transaction, TransactionOpenRequest>({
        query: (tor: TransactionOpenRequest) => ({
          url: `/Transactions/Open?transactionId=${tor.transactionId}&operatorId=${tor.operatorId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      printTransaction: builder.mutation<Transaction, TransactionPrintRequest>({
        query: (tpr: TransactionPrintRequest) => ({
          url: `/Transactions/Print?transactionId=${tpr.transactionId}&operatorId=${tpr.operatorId}`,
          method: 'POST',
        }),
      }),
      setTransactionTable: builder.mutation<void, TransactionSetTableRequest>({
        query: (tstr: TransactionSetTableRequest) => ({
          url: `/Transactions/SetTable?operatorId=${tstr.operatorId}&transactionId=${tstr.transactionId}&tableId=${tstr.tableId}&covers=${tstr.covers}`,
          method: 'PUT',
        }),
        invalidatesTags: ['Transactions'],
      }),
      clearTransactionTable: builder.mutation<void, TransactionClearTableRequest>({
        query: (tstr: TransactionClearTableRequest) => ({
          url: `/Transactions/ClearTable?operatorId=${tstr.operatorId}&transactionId=${tstr.transactionId}`,
          method: 'PUT',
        }),
        invalidatesTags: ['Transactions'],
      }),
      voidTransaction: builder.mutation<void, TransactionVoidRequest>({
        query: (tvr: TransactionVoidRequest) => ({
          url: `/Transactions/Void?operatorId=${tvr.operatorId}&transactionId=${tvr.transactionId}&voidReasonId=${tvr.voidReasonId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      tenderTransaction: builder.mutation<TransactionTenderResult, TransactionTenderRequest>({
        query: (ttr: TransactionTenderRequest) => ({
          url: `/Transactions/Tender?operatorId=${ttr.operatorId}&transactionId=${ttr.transactionId}&tenderMediaId=${
            ttr.tenderMediaId
          }&amount=${ttr.amount}${
            ttr.transactionPaymentReasonId !== undefined ? `&transactionPaymentReasonId=${ttr.transactionPaymentReasonId}` : ''
          }${ttr.pinEntryDeviceId !== undefined ? `&pinEntryDeviceId=${ttr.pinEntryDeviceId}` : ''}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      offlineCardTenderTransaction: builder.mutation<TransactionTenderResult, TransactionOfflineCardPaymentRequest>({
        query: (ttr: TransactionOfflineCardPaymentRequest) => ({
          url: `/Transactions/OfflineCardPayment?${generateQueryString(ttr)}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      completeTransaction: builder.mutation<boolean, TransactionCompleteRequest>({
        query: (tcr: TransactionCompleteRequest) => ({
          url: `/Transactions/Complete?operatorId=${tcr.operatorId}&transactionId=${tcr.transactionId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      splitTransaction: builder.mutation<Transaction[], TransactionSplitRequest>({
        query: (tsr: TransactionSplitRequest) => ({
          url: `/Transactions/Split?transactionId=${tsr.transactionId}&numberOfWays=${tsr.numberOfWays}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      addSplitTransaction: builder.mutation<Transaction[], number>({
        query: (transactionId: number) => ({
          url: `/Transactions/AddSplit?transactionId=${transactionId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      doneSplitTransaction: builder.mutation<Transaction[], number>({
        query: (transactionId: number) => ({
          url: `/Transactions/SplitDone?transactionId=${transactionId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      moveItemSplitTransaction: builder.mutation<Transaction[], TransactionSplitMoveItemRequest>({
        query: (request: TransactionSplitMoveItemRequest) => ({
          url: `/Transactions/MoveItemToSplit?newTransactionId=${request.newTransactionId}&transactionLineItemId=${request.transactionLineItemId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      transactionRedeemBooking: builder.mutation<Transaction[], TransactionRedeemBookingRequest>({
        query: (request: TransactionRedeemBookingRequest) => ({
          url: `/Transactions/RedeemBooking?operatorId=${request.operatorId}&bookingId=${request.bookingId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      cardTransaction: builder.mutation<void, TransactionCardRequest>({
        query: (request: TransactionCardRequest) => ({
          url: `/Transactions/Card?transactionId=${request.transactionId}&operatorId=${request.operatorId}&pinEntryDeviceId=${request.pinEntryDeviceId}&amount=${request.amount}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      linkCustomerLoyalty: builder.mutation<void, TransactionLinkCustomerLoyaltyRequest>({
        query: (request: TransactionLinkCustomerLoyaltyRequest) => ({
          url: `/Transactions/LinkCustomerLoyalty?transactionId=${request.transactionId}&operatorId=${request.operatorId}&customerId=${request.customerId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      linkCustomerLoyaltyCode: builder.mutation<void, TransactionLinkLoyaltyCodeRequest>({
        query: (request: TransactionLinkLoyaltyCodeRequest) => ({
          url: `/Transactions/${request.transactionId}/LinkCustomerLoyaltyWithCode`,
          method: 'POST',
          body: request,
        }),
        invalidatesTags: ['Transactions'],
      }),
      noSaleTransaction: builder.mutation<Transaction, TransactionNoSaleRequest>({
        query: (tor: TransactionNoSaleRequest) => ({
          url: `/Transactions/NoSale?transactionId=${tor.transactionId}&operatorId=${tor.operatorId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      refundTransaction: builder.mutation<Transaction, TransactionRefundRequest>({
        query: (tor: TransactionRefundRequest) => ({
          url: `/Transactions/Refund?transactionId=${tor.transactionId}&operatorId=${tor.operatorId}&pinEntryDeviceId=${tor.pinEntryDeviceId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      printLastTransactionReceipt: builder.mutation<void, number>({
        query: (operatorId: number) => ({
          url: `/Transactions/PrintLastTransactionReceipt?operatorId=${operatorId}`,
          method: 'POST',
        }),
      }),
      cancelCardPayment: builder.mutation<void, TransactionCancelCardRequest>({
        query: (e: TransactionCancelCardRequest) => ({
          url: `/Transactions/CancelCard?operatorId=${e.operatorId}&transactionId=${e.transactionId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      changeTender: builder.mutation<void, TransactionChangeTenderRequest>({
        query: (cr: TransactionChangeTenderRequest) => ({
          url: `/Transactions/change-tender?${generateQueryString(cr)}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      enableWasteModel: builder.mutation<TransactionWasteResponse, number>({
        query: (operatorId: number) => ({
          url: `/Transactions/enable-waste-mode?operatorId=${operatorId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
      addPackage: builder.mutation<void, PackagePurchaseRequest>({
        query: (request: PackagePurchaseRequest) => ({
          url: '/packages/purchase',
          method: 'POST',
          body: request,
        }),
        invalidatesTags: ['Transactions'],
      }),
      confirmEftFailure: builder.mutation<void, TransactionConfirmEftFailureRequest>({
        query: (request: TransactionConfirmEftFailureRequest) => ({
          url: '/transactions/confirm-eft-failure',
          method: 'POST',
          body: request,
        }),
        invalidatesTags: ['Transactions'],
      }),
      checkEftManually: builder.mutation<void, TransactionManualEftCheckRequest>({
        query: (request: TransactionManualEftCheckRequest) => ({
          url: `/transactions/check-eft-manually?transactionId=${request.transactionId}&cardRequestId=${request.cardRequestId}`,
          method: 'POST',
          body: request,
        }),
        invalidatesTags: ['Transactions'],
      }),
    }),
  });

export const {
  useAddSplitTransactionMutation,
  useCheckTableFreeQuery,
  useCompleteTransactionMutation,
  useCreateTransactionMutation,
  useGetStoredTransactionsQuery,
  useGetTransactionForOperatorQuery,
  useGetTransactionQuery,
  useLazyCheckTableFreeQuery,
  useLazyGetStoredTransactionsQuery,
  useLazyGetTransactionForOperatorQuery,
  useLazyGetTransactionQuery,
  useLazySearchTransactionsQuery,
  useMoveItemSplitTransactionMutation,
  useOpenTransactionMutation,
  usePrintTransactionMutation,
  useSearchTransactionsQuery,
  useSetTransactionTableMutation,
  useSplitTransactionMutation,
  useStoreTransactionMutation,
  useTenderTransactionMutation,
  useVoidTransactionMutation,
  useTransactionRedeemBookingMutation,
  useCardTransactionMutation,
  useLinkCustomerLoyaltyMutation,
  useNoSaleTransactionMutation,
  useDoneSplitTransactionMutation,
  useRefundTransactionMutation,
  usePrintLastTransactionReceiptMutation,
  useCancelCardPaymentMutation,
  useOfflineCardTenderTransactionMutation,
  useChangeTenderMutation,
  useEnableWasteModelMutation,
  useClearTransactionTableMutation,
  useLinkCustomerLoyaltyCodeMutation,
  useAddPackageMutation,
  useSaveToStoredTransactionMutation,
  useConfirmEftFailureMutation,
  useCheckEftManuallyMutation,
} = transactionSlice;
