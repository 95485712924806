import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import Price from '../../common/components/price/Price';
import logger from '../../common/logger/AppLogger';
import { PaymentMethodType, TenderMedia, TransactionStatus, useTenderTransactionMutation } from '../api';
import { useEftState, useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { clearOrderState, useAddToTransactionInProgress } from '../order/orderSlice';

import { setTransaction, useActiveTransactionId, useTenderMedias, useTransaction } from './tenderSlice';

function TenderMediaPanel() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eftState = useEftState();
  const transaction = useTransaction();
  const activeTransactionId = useActiveTransactionId();
  const tenderMedias = useTenderMedias();
  const operatorSession = useOperatorSession();
  const addToTransactionInProgress = useAddToTransactionInProgress();

  const [tenderTransactionTrigger] = useTenderTransactionMutation();
  // const [triggerManualCardPayment] = useOfflineCardTenderTransactionMutation();

  const [valueTyped, setValueTyped] = useState('0');
  const [isTendering, setIsTendering] = useState(false);

  if (activeTransactionId === undefined) return <></>;
  const activeTransaction = transaction.find((x) => x.id === activeTransactionId);
  if (activeTransaction === undefined) return <>No Transaction for ID found</>;

  const cashTender = tenderMedias.find((x) => x.paymentMethodType === PaymentMethodType.Cash);
  const cardTenders = tenderMedias.filter((x) => x.paymentMethodType === PaymentMethodType.Card);
  const loyaltyTenders = tenderMedias.filter((x) => x.name.toLowerCase().indexOf('loyalty') > -1);
  const depositTender = tenderMedias.find((x) => x.paymentMethodType === PaymentMethodType.Deposit);

  const showDepositButtonMode =
    activeTransaction.bookingId != undefined &&
    activeTransaction.bookingAmountRemaining &&
    activeTransaction.bookingAmountRemaining > 0 &&
    activeTransaction.bookingPaid === activeTransaction.bookingAmount;

  const cashTenderClick = () => {
    if (cashTender != undefined) addTenderToTransaction(cashTender, parseFloat(valueTyped) / 100);
  };

  const offlineCardTenderClick = () => {
    console.warn('eft state', eftState);
    if (eftState == true) return;
    else {
      const amount = parseFloat(valueTyped) / 100;
      if (activeTransaction.totalDue < amount) {
        dispatch(
          showErrorDialog({
            message: 'Cannot over tender for card payments',
            dismissible: true,
            buttonText: 'OK',
          }),
        );
      } else {
        const cardTender = cardTenders.length > 0 ? cardTenders[0] : undefined;
        if (amount > 0) {
          setValueTyped('0');
          navigate('./offline-card', { state: { amount, activeTransactionId, cardTender } });
        }
      }
    }
  };

  const cardTenderClick = () => {
    if (eftState == false) return;
    else {
      let amount = parseFloat(valueTyped) / 100;
      if (activeTransaction.totalDue < amount) {
        dispatch(
          showErrorDialog({
            message: 'Cannot over tender for card payments',
            dismissible: true,
            buttonText: 'OK',
          }),
        );
      } else {
        if (amount === 0) amount = activeTransaction.totalDue;
        if (amount > 0) {
          setValueTyped('0');
          navigate('./eft', { state: { amount, activeTransactionId } });
        }
      }
    }
  };

  const depositTenderClick = () => {
    if (depositTender != undefined && activeTransaction.bookingAmountRemaining) {
      let value = parseFloat(valueTyped) / 100;
      if (value === 0) {
        value =
          activeTransaction.totalDue > activeTransaction.bookingAmountRemaining
            ? activeTransaction.bookingAmountRemaining
            : activeTransaction.totalDue;
      }
      if (value <= activeTransaction.bookingAmountRemaining) addTenderToTransaction(depositTender, value);
      else {
        dispatch(
          showErrorDialog({
            message: `You have over tendered the deposit. The available deposit is £${activeTransaction.bookingAmountRemaining}`,
            dismissible: true,
            buttonText: 'OK',
          }),
        );
      }
    }
  };

  const cashDenominationClick = (value: number) => {
    if (cashTender != undefined) addTenderToTransaction(cashTender, value);
  };

  const addTenderToTransaction = (tenderMedia: TenderMedia, amount: number) => {
    if (tenderMedia.allowOverTender === false && amount > activeTransaction.totalDue)
      dispatch(showErrorDialog({ message: `You cannot over tender for ${tenderMedia.name}`, dismissible: true }));
    else if (tenderMedia.reasonRequired) logger.warn('Need to ask for a tender payment reason');
    else {
      if (activeTransaction && operatorSession) {
        setIsTendering(true);
        tenderTransactionTrigger({
          transactionId: activeTransaction.id,
          tenderMediaId: tenderMedia.id,
          amount: amount,
          operatorId: operatorSession.operator.id,
        })
          .unwrap()
          .then((data) => {
            if (data.transaction.status === TransactionStatus.Closed) {
              dispatch(setTransaction([]));
              dispatch(clearOrderState());
              navigate(`/tender/summary/${data.transaction.id}`);
            } else {
              setValueTyped('0');
              setIsTendering(false);
            }
          })
          .catch((err) =>
            dispatch(showErrorDialog({ message: err.data && err.data.detail ? err.data.detail : JSON.stringify(err), dismissible: true })),
          );
      }
    }
  };

  const clearClicked = () => {
    if (parseFloat(valueTyped) === 0) {
      if (transaction.length === 1) navigate('/order');
    } else setValueTyped('0');
  };
  return (
    <div className="pos-transaction__tender">
      <div className="pos-transaction__calculation">
        <div className="pos-transaction__data">
          <div className="pos-data pos-data_role_transaction">
            <div className="pos-data__list">
              <div className="pos-data__item pos-data__item_role_total">
                <p className="pos-data__item-label">T Due</p>
                <p className="pos-data__item-value">
                  <Price price={activeTransaction.totalValue} />
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_cash">
                <p className="pos-data__item-label">Cash</p>
                <p className="pos-data__item-value">
                  <Price
                    price={activeTransaction.transactionPayments
                      .filter((x) => cashTender !== undefined && cashTender.id === x.tenderMediaId)
                      .reduce((a, b) => a + b.tendered, 0)}
                  />
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_card">
                <p className="pos-data__item-label">Card</p>
                <p className="pos-data__item-value">
                  {' '}
                  <Price
                    price={activeTransaction.transactionPayments
                      .filter((x) => cardTenders.length > 0 && cardTenders.find((y) => y.id == x.tenderMediaId) !== undefined)
                      .reduce((a, b) => a + b.tendered, 0)}
                  />
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_loyalty">
                <p className="pos-data__item-label">Loyalty</p>
                <p className="pos-data__item-value">
                  {' '}
                  <Price
                    price={activeTransaction.transactionPayments
                      .filter((x) => loyaltyTenders.find((y) => y.id === x.tenderMediaId) !== undefined)
                      .reduce((a, b) => a + b.tendered, 0)}
                  />
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_deposit">
                <p className="pos-data__item-label">Deposit</p>
                <p className="pos-data__item-value">
                  {' '}
                  <Price
                    price={activeTransaction.transactionPayments
                      .filter((x) => depositTender !== undefined && depositTender.id === x.tenderMediaId)
                      .reduce((a, b) => a + b.tendered, 0)}
                  />
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_balance is-active">
                <p className="pos-data__item-label">Balance</p>
                <p className="pos-data__item-value">
                  <Price price={activeTransaction.totalDue} />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-transaction__numpad">
          <div className="pos-numpad pos-numpad_role_transaction">
            <div className="pos-numpad__display">
              <Price price={parseFloat(valueTyped) / 100} />
            </div>
            <div className="pos-numpad__controls">
              <div className="pos-numpad__controls-row">
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 1)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  1
                </button>
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 2)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  2
                </button>
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 3)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  3
                </button>
              </div>
              <div className="pos-numpad__controls-row">
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 4)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  4
                </button>
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 5)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  5
                </button>
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 6)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  6
                </button>
              </div>
              <div className="pos-numpad__controls-row">
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 7)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  7
                </button>
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 8)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  8
                </button>
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 9)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  9
                </button>
              </div>
              <div className="pos-numpad__controls-row">
                <button
                  className="pos-numpad__control"
                  type="button"
                  onClick={() => setValueTyped(valueTyped + 0)}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  0
                </button>
                <button
                  className="pos-numpad__control pos-numpad__control_role_clear"
                  type="button"
                  onClick={clearClicked}
                  disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
                >
                  CLR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pos-transaction__options">
        <div className="pos-transaction__denominations">
          <button
            className={`pos-transaction__denomination${cashTender === undefined || showDepositButtonMode == true ? ' hidden-button' : ''}`}
            disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
            type="button"
            onClick={() => cashDenominationClick(20)}
          >
            £20
          </button>
          <button
            className={`pos-transaction__denomination${cashTender === undefined || showDepositButtonMode == true ? ' hidden-button' : ''}`}
            disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
            type="button"
            onClick={() => cashDenominationClick(10)}
          >
            £10
          </button>
          <button
            className={`pos-transaction__denomination${cashTender === undefined || showDepositButtonMode == true ? ' hidden-button' : ''}`}
            disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
            type="button"
            onClick={() => cashDenominationClick(5)}
          >
            £5
          </button>
        </div>
        <div className="pos-transaction__methods">
          <button
            className={`pos-transaction__method pos-transaction__method_role_cash${
              cashTender === undefined || showDepositButtonMode == true ? ' hidden-button' : ''
            }`}
            disabled={
              activeTransaction.cardPaymentInProgress ||
              (parseFloat(valueTyped) === 0 && activeTransaction.totalDue > 0) ||
              isTendering ||
              addToTransactionInProgress
            }
            type="button"
            onClick={cashTenderClick}
          >
            Cash
          </button>
          <button
            className={`pos-transaction__method pos-transaction__method_role_deposit${
              depositTender === undefined || showDepositButtonMode == false ? ' hidden-button' : ''
            }`}
            type="button"
            disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
            onClick={depositTenderClick}
          >
            Deposit
          </button>
          {eftState === true && (
            <button
              className={`pos-transaction__method pos-transaction__method_role_card${
                cardTenders.length == 0 || showDepositButtonMode == true ? ' hidden-button' : ''
              }`}
              type="button"
              disabled={activeTransaction.cardPaymentInProgress || isTendering || addToTransactionInProgress}
              onClick={cardTenderClick}
            >
              Card
            </button>
          )}{' '}
          {eftState === false && (
            <button
              className={`offline-card pos-transaction__method pos-transaction__method_role_card${
                cardTenders.length == 0 || showDepositButtonMode == true ? ' hidden-button' : ''
              }`}
              type="button"
              disabled={
                activeTransaction.cardPaymentInProgress ||
                (parseFloat(valueTyped) === 0 && activeTransaction.totalDue > 0) ||
                isTendering ||
                addToTransactionInProgress
              }
              onClick={offlineCardTenderClick}
            >
              Offline Card
            </button>
          )}
        </div>
      </div>
      {addToTransactionInProgress && (
        <div className="pos-transaction-adding-warning">Item still adding to basket, please wait a moment...</div>
      )}
    </div>
  );
}

export default TenderMediaPanel;
